import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer p-top-90 p-bottom-50">

            {/* Footer top */}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-3 col-md-6">
                            {/* Brand */}
                            <div className="footer-widget">
                                <Link to="/" className="brand-img">
                                    <img className="me-4 p-2 bg-white rounded" src="/assets/img/logos/logo.svg" srcSet="/assets/img/logos/footer-logo@2x.svg 2x" alt="" />
                                </Link>
                                <p className="brand-desc">
                                    <em>
                                        TripNow is a trusted travel agency based in Varanasi, India, offering expertly curated tour packages to iconic spiritual and cultural destinations.
                                    </em>
                                    <Link to="/about-us" className="text-light"> [+] </Link>
                                </p>
                                <ul className="social-list">
                                    <li className="social-item">
                                        <Link to="https://www.facebook.com/profile.php?id=61573548430231" className="d-inline-flex align-items-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 50 50">
                                                <path fill="#ffffff" d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="https://www.instagram.com/tripnowcc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="d-inline-flex align-items-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 30 30">
                                                <path fill="#ffffff" d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="https://x.com/TripNowcc" className="d-inline-flex align-items-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 50 50">
                                                <path fill="#ffffff" d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="https://www.youtube.com/@tripnowcc" className="d-inline-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 24 24">
                                                <path fill="#ffffff" d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"></path>
                                            </svg>
                                        </Link >
                                    </li>
                                </ul>
                            </div>
                            {/* /Brand */}
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            {/* Contact Info */}
                            <div className="footer-widget">
                                <h2 className="h4 pb-3">Contact Info</h2>
                                <div className="contact-info">
                                    <p>
                                        <span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span>
                                    </p>
                                    <p>
                                        <span>+91 9236975458</span>
                                    </p>
                                    <p>
                                        <Link to="mailto:Booking@example.com" className="text-light">tripnow.cc@gmail.com</Link>
                                    </p>

                                </div>
                            </div>
                            {/* /Contact Info */}
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            {/* Quick Links */}
                            <div className="footer-widget">
                                <h2 className="h4 pb-3">TripNow</h2>
                                <ul className="footer-link">
                                    <li className="link-item">
                                        <Link to="/about-us" className="text-light">About us</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="/destinations" className="text-light">Destinations</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="/tour-packages" className="text-light">TripNow Tours</Link>
                                    </li>

                                    <li className="link-item">
                                        <Link to="/contact-us" className="text-light">Contact us</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* /Quick Links */}
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            <div className="footer-widget">

                                <h2 className="h4 pb-3">Packages</h2>
                                <ul className="footer-link">
                                    <li className="link-item">
                                        <Link to="tour-packages/explore-varanasi" className="text-light">
                                            Visit Varanasi
                                        </Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="tour-packages/explore-agra" className="text-light">
                                            Visit Agra
                                        </Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="tour-packages/explore-ayodhya" className="text-light">
                                            Visit Ayodhya
                                        </Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="tour-packages/explore-prayagraj" className="text-light">
                                            Visit Prayagraj
                                        </Link >
                                    </li>
                                </ul>
                                {/* /Social */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Footer top */}

            {/* Footer bottom */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p className="mb-lg-0">
                                Copyright &copy; 2024 TripNow. All rights reserved. Developed by{' '}
                                <Link className="text-light" to="https://quantumwebs.in" target="_blank" rel="noopener noreferrer">
                                    Quantumwebs Technologies
                                </Link>
                            </p>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="text-start text-md-end">
                                <ul className="list-inline mb-lg-0">
                                    <li className="list-inline-item">
                                        <Link to="/privacy-policy" className="text-light">Privacy Policy</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/terms-conditions" className="text-light">Terms of Use</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/cancellation-policy" className="text-light">Cancellation & Refund Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Footer bottom */}

        </footer>
    );
};

export default Footer;
